<template>
	<div>
		<a-modal :title="getTitle" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="edit-level-form">
						<div class="edit-level-form-name">
							<a-input style="width: 480px;" prefix="等级名" placeholder="请输入等级名称" v-model="form.name" :maxLength="10">
							</a-input>
						</div>
						
						<div class="flex alcenter pl15 mt24 mb24">
							<div>选择等级排序（越大等级越高）：</div>
							<div class="flex wrap">
								<template v-for="(item,index) in colors">
									<div class="flex alcenter">
										<div class="mr5">V{{item.id}}</div>
										<div class="edit-level-form-color-item active" v-if="item.id==form.vip_level" :style="{ background:item.color }">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="edit-level-form-color-item clickAct" @click="checkIt(index)" v-else :style="{ background:item.color }"></div>
									</div>
								</template>
								
							</div>
						</div>
						
						<div style="position: relative;" class="edit-level-form-discount">
							<a-input-number v-model="form.discount"  :precision="1" :min="0" :max="10" >
								
							</a-input-number>
							<div class="input-number-addon">会员折扣</div>
							<div class="input-number-footer">折</div>
						</div>
						
						<div class="mt24">
							<div class=" flex alcenter space">
								<div>
									<div class="pl15 ft14 ftw500 cl-info">所需积分数</div>
									<div class="mt10">
										<a-input-number style="width: 195px;"  placeholder="请输入积分数" :precision="0" :min="0" v-model="form.need_integral"  />
									</div>
								</div>
								
								<div class="edit-level-form-or">或</div>
								
								<div>
									<div class="pl15 ft14 ftw500 cl-info">充值金额达到</div>
									<div class="mt10">
										<a-input-number style="width: 195px;"  placeholder="请输入充值金额" :precision="2" :min="0" v-model="form.need_recharge"  />
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			level_id:{
				type:Number,
				default:0
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					name:'',
					discount:0,
					need_integral:0,
					need_recharge:0,
					vip_level:'',
				},
				vip_levels:[],
			}
		},
		created() {
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.level_id==0){
					return "添加会员等级";
				}else{
					return "编辑会员等级"
				}
			},
			colors(){
				let pre_colors=[
					{id:1,color:'#DCAD85'},
					{id:2,color:'#AEB2C1'},
					{id:3,color:'#AAC4E7'},
					{id:4,color:'#FED064'},
					{id:5,color:'#B961E8'}
				];
				let arr=new Array;
				for(var a in pre_colors){
					if(this.vip_levels.indexOf(pre_colors[a].id) == -1){
						arr.push(pre_colors[a]);
					}
				}
				
				return arr;
			}
		},
		methods: {
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/member/showLevel',{
					level_id:this.level_id
				}).then(res=>{
					this.vip_levels=res.vip_levels;
					if(res.detail){
						this.form=res.detail;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.handleCancel();
				})
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('platform/member/saveLevel',{
					level_id:this.level_id,
					name:this.form.name,
					vip_level:this.form.vip_level,
					need_integral:this.form.need_integral,
					need_recharge:this.form.need_recharge,
					discount:this.form.discount,
				}).then(res=>{
					this.$message.success('操作成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
				
			},
			
			checkIt(index){
				this.form.vip_level=this.colors[index].id;
			},
			
			
		}
	}
</script>

<style>
	.edit-level-form-name .ant-input{
		height: 40px;
	}
	
	.edit-level-form-name .ant-input-affix-wrapper .ant-input-prefix {
	    position: absolute;
	    top: 50%;
	    z-index: 2;
	    display: flex;
	    align-items: center;
	    color: rgba(0, 0, 0, 0.65);
	    line-height: 0;
	    transform: translateY(-50%);
	    width: 50px;
		height: 40px;
	}
	
	.edit-level-form-name .ant-input-affix-wrapper .ant-input:not(:first-child) {
	    padding-left: 74px;
	}
	
	.edit-level-form-discount .ant-input-number {
		font-size: 12px;
		line-height: 40px;
		font-weight: 400;
		color: #A6AEC2;
		width: 480px;
		height: 40px;
		border-radius: 4px;
		
	}
	
	
	.edit-level-form-discount .ant-input-number-input {
		width: 480px;
		height: 40px;
		padding: 0 80px;
		border-radius: 4px;
	}
	
	.edit-level-form-discount .ant-input-number-handler-wrap {
		display: none;
	}
	
	.edit-level-form-discount .input-number-addon{
		height: 40px;
		padding-left: 16px;
		position: absolute;
		top: 0px;
		left: 0;
		text-align: left;
		line-height: 40px;
		
		font-size: 12px;
		font-weight: 400;
		color: #232529;
	}
	
	.edit-level-form-discount .input-number-footer{
		height: 40px;
		padding-right: 36px;
		position: absolute;
		top: 0px;
		right: 0;
		line-height: 40px;
		
		font-size: 12px;
		font-weight: 400;
		color: #656A75;
	}
	
	.edit-level-form-color-item{
		width: 20px;
		height: 20px;
		border-radius: 4px;
		margin-right: 10px;
		line-height: 24px;
		text-align: center;
	}
	
	.edit-level-form-color-item .active{
		box-shadow: 0px 2px 4px 0px rgba(220, 173, 133, 0.3);
	}
	
	.edit-level-form-or{
		width: 30px;
		height: 30px;
		background: rgba(71, 114, 255, 0.06);
		border-radius: 15px;
		
		font-size: 14px;
		font-weight: 500;
		color: #4772FF;
		line-height: 30px;
		text-align: center;
	}
</style>
