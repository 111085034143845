<template>
	<div>
		<a-spin :spinning="loading">
			<div>
				<div class="ft20 cl-black cl-main ftw500">会员等级</div>
				<div class="mt20">
					 <a-alert message="会员等级最多可设置五级，可修改等级权益和条件。成为会员后，根据用户的充值金额或积分值系统自动来划分等级。会员折扣作为核销时参考作用,目前不作为项目及商城购物折扣使用.买单功能会使用折扣" banner />
				</div>
				
				<div class="mt20">
					<a-button @click="addLevelAct()" style="width: 124px;" type="primary" ghost><i class="iconfont iconadd_small mr5"></i>添加等级</a-button>
				</div>
				
				<div class="mt20">
					<div class="flex wrap">
						<div class="member-rank-item" v-for="(item,index) in datas">
							<div class="member-rank-item-header flex space alcenter">
								<div class="ft16 ftw500 cl-black">{{item.name}}</div>
								<div>
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											  <a-menu-item>
												<a class="menu-act" href="javascript:;" @click="editLevelAct(index)">
													<i class="iconfont ft14 iconedit"></i>
													<span class="ml10">编辑等级</span>
												</a>
											  </a-menu-item>
											
											  <a-menu-item>
												<a class="menu-act" href="javascript:;" @click="delLevelAct(index)">
													<i class="iconfont ft14 icondelete"></i>
													<span class="ml10">删除等级</span>
												</a>
											  </a-menu-item>
									    </a-menu>
									  </a-dropdown>
									
								</div>
							</div>
						
							<div class="member-rank-item-content flex ">
								<div class="member-rank-item-content-sign" :style="{ background: colors[item.vip_level]}">
									<div class="member-rank-item-content-sign-level">V{{item.vip_level}}</div>
									<div class="flex alcenter center" style="height: 100%;">
										<i class="iconfont iconvip ft40 cl-w"/>
									</div>
								</div>
								
								<div class="member-rank-item-content-condition">
									<div style="width: 100%; " class="flex ">
										<div class="member-rank-item-content-condition-item">
											<div>
												<div class="ft14 ftw400 cl-info text-center text-over4">所需积分数</div>
												<div class="ft20  cl-main text-over4 text-center mt10">{{item.need_integral}}</div>
											</div>
										</div>
										<div class="member-rank-item-content-condition-item">
											<div class="member-rank-item-content-condition-item-or">或</div>
										</div>
										<div class="member-rank-item-content-condition-item">
											<div>
												<div class="ft14 ftw400 cl-info text-center text-over4">充值金额达到</div>
												<div class="ft20  cl-main text-over4 text-center mt10">¥{{item.need_recharge}}</div>
											</div>
										</div>
										<div class="member-rank-item-content-condition-item">
											 <div class="member-rank-item-content-condition-item-divider"></div>
										</div>
										<div class="member-rank-item-content-condition-item">
											<div>
												<div class="ft14 ftw400 cl-info text-center text-over4">会员折扣</div>
												<div class="ft20  cl-main text-over4 text-center mt10">{{item.discount}}折</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			
				<div v-if="editLevelLoading">
					<edit-level :level_id="level_id" :visible="editLevelLoading" @cancel="cancelEditLevel" @ok="okEditLevel"></edit-level>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import editLevel from './components/level/modal/editLevel.vue';
	export default{
		components:{
			editLevel,
		},
		data(){
			return {
				loading:false,
				editLevelLoading:false,
				level_id:0,
				colors:[],
				datas:[],
			}
		},
		created() {
			this.colors[1]='#DCAD85';
			this.colors[2]='#AEB2C1';
			this.colors[3]='#AAC4E7';
			this.colors[4]='#FED064';
			this.colors[5]='#B961E8';
			this.getLists();
		},
		methods:{
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/member/getLevelList',{
					
				}).then(res=>{
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			addLevelAct(){
				if(this.datas.length>=5){
					this.$message.error('你已有五个等级，无法添加了')
					return;
				}
				this.level_id=0;
				this.editLevelLoading=true;
			},
			editLevelAct(index){
				this.level_id=this.datas[index].level_id;
				this.editLevelLoading=true;
			},
			cancelEditLevel(){
				this.editLevelLoading=false;
			},
			okEditLevel(){
				this.editLevelLoading=false;
				this.getLists();
			},
			
			delLevelAct(index){
				this.$confirm({
					title: '确定删除这个等级吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/member/delLevel', {
								level_id: this.datas[index].level_id,
							}).then(res => {
								this.$message.success('操作成功');
								this.getLists();
							}).catch(res => {
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
						
					},
				});
			}
		}
	}
</script>

<style>
	
	.member-rank-item-header{
		height: 59px;
		padding: 0px 20px 0px 20px;
		border-bottom: 1px solid #EBEDF5;
	}
	
	.member-rank-item-operate{
		width: 32px;
		height: 32px;
		border-radius: 32px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		text-align: center;
		line-height: 32px;
	}
	
	.member-rank-item .item:hover{
		color: #4772FF;
	}
	
	.member-rank-item-content{
		height: 140px;
		padding: 20px;
	}
	
	.member-rank-item-content-sign{
		width: 100px;
		height: 100px;
		position: relative;
	}
	
	.member-rank-item-content-sign-level{
		position: absolute;
		top: 10px;
		width: 32px;
		height: 20px;
		background: linear-gradient(135deg, #4E4E4E 0%, #262728 100%);
		border-radius: 0px 15px 15px 0px;
		
		font-size: 12px;
		color: #F7E3C3;
		line-height: 20px;
		text-align: center;
	}
	
	.member-rank-item-content-condition{
		width: calc(100% - 100px);
	}
	.member-rank-item-content-condition-item{
		width: 20%;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.member-rank-item-content-condition-item-or{
		width: 30px;
		height: 30px;
		background: rgba(71, 114, 255, 0.06);
		border-radius: 15px;
		
		font-size: 14px;
		font-weight: 500;
		color: #4772FF;
		line-height: 30px;
		text-align: center;
	}
	
	.member-rank-item-content-condition-item-divider{
		width: 1px;
		height: 60px;
		background: #EBEDF5;
	}
	
	
	@media screen and (max-width: 1490px) {
		.member-rank-item{
			width: 99%;
			height: 200px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #EBEDF5;
			margin-right: 1%;
			margin-bottom: 1%;
		}
	}
	@media screen and (min-width: 1491px) {
		.member-rank-item{
			width: 49%;
			height: 200px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #EBEDF5;
			margin-right: 1%;
			margin-bottom: 1%;
		}
	}
</style>
